export interface ItemsSelectReportingPeriod {
  label: string
  id: number
}

function generateItemsSelectReportingPeriod(
  startQuarter: string,
  startYear: number
): ItemsSelectReportingPeriod[] {
  const currentYear = new Date().getFullYear()
  const startQuarterIndex = ['I', 'II', 'III', 'IV'].indexOf(startQuarter)
  return new Array(currentYear - startYear + 1)
    .fill(0)
    .map((x, i) => i + startYear)
    .flatMap(year => ['I', 'II', 'III', 'IV'].map(quarter => `${quarter} квартал ${year} года`))
    .map((label, i) => ({ label, id: i }))
    .slice(startQuarterIndex)
}

export const itemsSelectReportingPeriod: ItemsSelectReportingPeriod[] = [
  ...generateItemsSelectReportingPeriod('III', 2023),
]
