import React, { useEffect, useMemo, useState } from 'react'
import { initState, ReportState } from '../../../store/report'
import { getReportByIdState } from '../../../shared/Api/reports'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { useNavigate, useParams } from 'react-router-dom'
import styles from '../ReportReview/ReportReview.module.css'
import { Button } from '@consta/uikit/Button'
import { getMappedDataForEditor } from '../../../store/report/selectors'
import ReportViewAsEditor from '../../../components/ReportComponents/ReportViewAsEditor/ReportViewAsEditor'
import { quarterToDate } from '../../../shared/utils/quarterToDate'
import subtractIcon from '../../../assets/icons/subtract.svg'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

const ReportViewById = ({ authData }: { authData: StatusAuthData }) => {
  const [report, setReport] = useState<ReportState>(initState)
  const { reportId } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const startFetching = async () => {
      try {
        const response = await getReportByIdState(reportId as string)
        if (response.status === 403) {
          navigate('/reports')
        }
        setReport({
          ...response,
          original: response.original_report ?? {},
          actual: response.actual_report ?? {},
          arguments: response.argument_report ?? {},
          files: response.files_report ?? {},
        })
      } catch (error: any) {
        console.log(error)
      }
    }
    startFetching()
  }, [reportId, navigate])
  const handleOnIconClick = (field: string) => {
    const municipal_entity = report?.municipal_entity ?? ''
    let from = ''
    let until = ''
    if (report?.year && report?.quarter) {
      const date = quarterToDate(report.quarter)
      from = `${report.year}-${date.from}`
      until = `${report.year}-${date.until}`
    }
    const queryParams = new URLSearchParams({
      'criteria[]': field,
      municipal_entity,
      from,
      until,
    })
    navigate(`/activity?${queryParams.toString()}`)
  }
  const sectionsForEditor = useMemo(() => getMappedDataForEditor(report), [report])
  const sectionsContentForEditor = sectionsForEditor.map((section, i) => (
    <ReportViewAsEditor
      key={i}
      title={section.title}
      list={section.items}
      handleOnIconClick={handleOnIconClick}
    />
  ))
  const title =
    report?.type === 'year'
      ? `Просмотр годового отчета за ${report?.year} год`
      : `Просмотр отчета за ${report?.quarter} квартал ${report?.year} года`
  return (
    <div className={styles.main}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          marginBottom: '24px',
        }}
      >
        <h2 className={styles.mainTitle}>{title}</h2>
        {authData?.role === UserRolesEnum.EDITOR && (
          <a href={`/api/reports/export-as-pdf/${reportId}`} target="_blank">
            <img src={subtractIcon} alt='export' />
          </a>
        )}
        {authData?.role === UserRolesEnum.DEPUTY &&
          ['approved', 'auto_approved'].includes(report.status) && (
            <a href={`/api/reports/export-as-pdf/${reportId}`} target="_blank">
              <img src={subtractIcon} alt='export' />
            </a>
          )}
      </div>
      {sectionsContentForEditor}
      <div className={styles.footerContainer}>
        <Button
          label='Назад'
          view='ghost'
          style={{ width: '240px', marginRight: '24px' }}
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  )
}

export default ReportViewById
